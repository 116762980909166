<!--  -->
<template>
  <div class="container">
    <div class="header">
      <div class="order-manage" @click="linkOrderList()">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230725151821619.png" />
        <span>订单管理</span>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="pro-item" v-for="(item, index) in datalist" :key="index" @click="handleDetail(item)">
        <img class="pro-image" :src="item.coverImg" />
        <div class="pro-info">
          <div class="pro-name">{{ item.courseTitle }}</div>
          <div class="sale-num">销量:{{ item.salesCount }}</div>
          <div class="albtm">
            <div class="vip-price">店主价:<span>￥{{ item.price }}</span></div>
            <div class="retail-price">零售价:<span>￥{{ item.linePrice }}</span></div>
          </div>
        </div>
        <van-button @click.stop="handlePurchase(item)">立即购买</van-button>
      </div>
    </van-list>
  </div>
</template>

<script>
import {  replaceAppToken } from "../../utils/appUtil";
export default {
  name: '',
  data() {
    return {
      pageIndex: 0,
      pageSize: 10,
      loading: true,
      finished: false,
      datalist: [],
      isInApp: false,
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) {
      this.isInApp = true;
      replaceAppToken(token, () => {
        this.init();
      });
    } else {
      this.init()
    }
  },
  methods: {
    init() {
      this.onLoad();
    },
    onLoad() {
      this.pageIndex++;
      this.get("/GroupBuy/OnlineCourse/GetCourseList", {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let list = res.response || [];
          if (list.length < this.pageSize) {
            this.finished = true;
          }
          this.datalist = this.datalist.concat(list);
        }
      })
    },
    handleDetail(item) {
      this.$router.push("/online/productDetail?proId=" + item.id + "&isapp=" + (this.isInApp ? 1 : 0))
    },
    //点击购买
    handlePurchase(item) {
      this.$router.push("/Recharge/courseConfirm?proId=" + item.id + "&isapp=" + (this.isInApp ? 1 : 0))
    },
    linkOrderList() { 
      this.$router.push("/course/orderList?isapp=" + (this.isInApp ? 1 : 0))
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  padding-top: 58px;
  box-sizing: border-box;
  background-color: #F4F4F4;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;
    padding: 5px 15px;
    text-align: right;
    background-color: #fff;

    .order-manage {
      padding: 11px 0;
      display: inline-flex;
      align-items: center;

      img {
        width: 16px;
        margin-right: 5px;
      }
    }
  }

  .van-list {
    height: calc(100% - 58px);

    .pro-item {
      display: flex;
      position: relative;
      padding: 11px 15px;
      background-color: #fff;

      .pro-image {
        width: 125px;
        height: 125px;
        margin-right: 10px;
      }

      .pro-info {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;

        .pro-name {
          color: #333;
          font-size: 15px;
        }

        .sale-num {
          color: #999;
          font-size: 12px;
          padding: 11px 0;
        }

        .albtm {
          margin-top: auto;
        }

      }

      .vip-price {
        color: #333;
        font-size: 14px;

        span {
          color: #FF98A0;
          font-size: 15px;
        }
      }

      .retail-price {
        color: #999;
        padding-top: 5px;
        text-decoration: line-through;

        span {
          color: #333;
        }
      }

      .van-button {
        position: absolute;
        right: 15px;
        bottom: 11px;
        height: 28px;
        color: #fff;
        padding: 0 6px;
        border-radius: 28px;
        background-color: #FF98A0;
      }

      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          left: 15px;
          right: 15px;
          bottom: 0;
          height: 1px;
          background-color: #EEEEEE;
        }
      }
    }
  }
}
</style>